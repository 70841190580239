<script setup lang="ts">
import { SubmitDataErrors, SubmitDataModel } from '~/types/hubspot-form-types'

const props = defineProps<{
  portalId: string
  formGuid: string
  schema: any
}>()

const { handleSubmit, isSubmitting, setErrors } = useForm({
  validationSchema: props.schema,
})

const route = useRoute()

const formRef = ref()

const dimensions = reactive({ height: undefined, width: undefined })

const submitUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${props.portalId}/${props.formGuid}`

function mapErrors(errors: SubmitDataErrors) {
  const res: Record<string, string> = {}
  if (!errors) return res
  errors.forEach((item) => {
    const match = /'fields\.(.*?)'/.exec(item.message)
    const field = match ? match[1] : null

    const msgs = item.message.split('.')
    if (!field || !msgs?.[2]) return
    res[field] = msgs[2]
  })

  return res
}

const isSuccess = ref(false)
const successMessage = ref('')

const onSubmit = handleSubmit(async (values) => {
  const body: SubmitDataModel = {
    fields: Object.entries(values)
      .map(([name, value]) => ({ name, value }))
      .filter((i) => i.name && i.value),

    context: {
      pageUri: route.fullPath,
      pageName: String(route.name),
    },
  }
  const res = await fetch(submitUrl, {
    body: JSON.stringify(body),
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const json = await res.json()

  if (json.status === 'error') setErrors(mapErrors(json?.errors))
  else {
    if (!process.client || !window || !window.dataLayer) return
    // @ts-ignore
    window.dataLayer.push({ event: 'send_contact_form' })

    isSuccess.value = true
    successMessage.value = json?.inlineMessage

    if (!formRef.value) return
    dimensions.height = formRef.value.clientHeight
    dimensions.width = formRef.value.clientWidth
  }
})
</script>

<template>
  <transition
    enter-active-class="transition-opacity duration-150 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-150 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    mode="out-in"
  >
    <form v-if="!isSuccess" v-bind="$attrs" ref="formRef" @submit.prevent="onSubmit" novalidate>
      <slot :is-submitting="isSubmitting" />
    </form>

    <div
      class="flex justify-items-center items-center text-center"
      :style="`height: ${dimensions.height}px; width: ${dimensions.width}px`"
      v-else
    >
      {{ successMessage }}
    </div>
  </transition>
</template>
