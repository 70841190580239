<script setup lang="ts">
import { vMaska } from 'maska'

const props = defineProps<{
  name: string
  placeholder?: string
  type?: string
  label?: string
  mask?: string
  maskTokens?: string
}>()

const { value, errors } = useField(props.name)
</script>

<template>
  <div>
    <label class="flex flex-col text-black shadow-xs">
      <span v-if="label" class="text-sm mb-1">
        {{ label }}
      </span>

      <input
        class="py-2.5 px-3.5 border border-1 border-gray-300 rounded placeholder-gray-250"
        :type="type"
        :placeholder="placeholder"
        v-model="value"
        v-maska
        :data-maska-tokens="maskTokens"
        :data-maska="mask"
        data-maska-eager
      />
    </label>
    <small class="text-xs text-red-600">{{ errors[0] }}</small>
  </div>
</template>

<style scoped lang="scss"></style>
